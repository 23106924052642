<template>
  <div class="home flex-col" >
    <!-- 图片那块附近的 -->
    <div class="achAndMsg-container">
       <!-- <swiper
          key="msgImg1"
          :options="swiperOption1"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          class="Msg-img1"
        >
          <template v-for="(item, index) in homeImgArr">
            <swiper-slide :key="index"> -->
              <!-- {{item.thumb.split(',')}} -->
              <!-- <img :src="item.img" alt="">
              </swiper-slide>
          </template>

          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper> -->
      <!-- 4个字 -->
      <!-- <div class="achievement">
        <div class="achievement-word">
          <span class="achievement-word-number">
            <img src="../assets/images/IconOutlineNewspaper.png" alt="">
            {{ IndustryinfoData.xqdjs || 0 }}
          </span>
          <span class="achievement-word-wz">需求对接</span>
        </div>
        <div class="line-box">
          <div class="line"></div>
          <div class="line-coarse"></div>
        </div>
        <div class="achievement-word">
          <span class="achievement-word-number">
            <img src="../assets/images/IconOutlineOffice-building.png" alt="">
            {{ IndustryinfoData.crxqys || 0 }}
            </span>
          <span class="achievement-word-wz">合作企业</span>
        </div>
        <div class="line-box">
          <div class="line"></div>
          <div class="line-coarse"></div>
        </div>
        <div class="achievement-word">
          <span class="achievement-word-number">
            <img src="../assets/images/IconOutlineHome.png" alt="">
            {{
            IndustryinfoData.hzyxs || 0
          }}</span>
          <span class="achievement-word-wz">合作院校</span>
        </div>
        <div class="line-box">
          <div class="line"></div>
          <div class="line-coarse"></div>
        </div>
        <div class="achievement-word">
          <span class="achievement-word-number">
            <img src="../assets/images/IconOutlineFlag.png" alt="">
            {{
            IndustryinfoData.cxypts || 0
          }}</span>
          <span class="achievement-word-wz">产学研平台</span>
        </div>
      </div> -->
    </div>
    <div class="achievement-container">
      <div class="achievement-list">
        <div class="achievement-item">
          <span class="achievement-word-number">
           <!-- {{ IndustryinfoData.xqdjs || 0 }} -->
           875
          </span>
          <span class="achievement-word-title">
            <img src="../assets/images/需求对接.png" alt="">
            <span>需求对接</span> 
          </span>
        </div>
        <div class="achievement-item">
          <span class="achievement-word-number"> 
            <!-- {{ IndustryinfoData.qysbs || 0 }} -->
            2125
          </span>
          <span class="achievement-word-title">
            <img src="../assets/images/企业合作.png" alt="">
            <span>合作企业</span> 
          </span>
        </div>
        <div class="achievement-item">
          <span class="achievement-word-number">
            <!-- {{ IndustryinfoData.hzyxs || 0 }} -->
            58
          </span>
          <span class="achievement-word-title">
            <img src="../assets/images/合作院校.png" alt="">
            <span>合作院校</span> 
          </span>
        </div>
        <div class="achievement-item">
          <span class="achievement-word-number">
            <!-- {{ IndustryinfoData.cxypts || 0 }} -->
            228
          </span>
          <span class="achievement-word-title">
            <img src="../assets/images/产学研品台.png" alt="">
            <span>产学研平台</span> 
          </span>
        </div>
      </div>
    </div>
    <!-- 信息资讯 -->
    <div class="getMessage-container justify-center" v-loading="fullscreenLoading">

      <div class="messageCard">
        <div class="messageCard-title">
          <div class="title-left">
            <img src="../assets/images/信息资讯.png" alt="">
            <span>信息资讯</span>
          </div>
          <div class="title-right" @click="HandleToMoreInfo">
            <span style="cursor: pointer;">查看更多</span>
            <img src="../assets/images/查看更多.png" alt="">
          </div>
        </div>
        <div class="messageCard-content">
        <!-- 左侧图片 -->
          <swiper
            key="msgImg"
            :options="swiperOption"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            class="Msg-img"
          >
            <div v-for="(item, index) in news" :key="item.make_time">
              <swiper-slide >
                <!-- {{item.thumb.split(',')}} -->
                <el-image
                  class="themImg"
                  :src="item.thumb ? httpConfig.baseURL + item.thumb.split(',')[0] : ''"
                  alt=""
                  :fit="'fill'"
                />
                <!-- 图片文字 -->
                <div class="Msg-img-mask">
                  <span class="Msg-img-mask-word">{{ item.title }}</span>
                </div></swiper-slide
              >
            </div>

            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>

          <!-- 右侧文字 -->
          <div class="word-container">
            <!-- <span class="word-container-title">信息资讯</span> -->
            <!-- 线 -->
            <!-- <span class="word-title-liner"></span> -->
            <!-- 字 -->
            <template v-for="(item, index) in newsRight">
              <div
                class="word-title-liner-wz"
                :key="item.send_data"
                @click="jumpNewDetail(item.billid)"
              >
                <div class="container99">
                  <div class="text">
                    <!-- <span>
                      <img  src="../assets/images/小三角形.png" /> 
                    </span> -->
                    <span class="word-title-liner-wz-word">
                      {{item.title}}
                    </span>
                  </div>
                  <div>
                    <span class="riqi">{{ item.send_data }}</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- 产业集群 -->
    <div class="group1">
      <!-- <div class="title">产业集群</div>
      <div class="subtitle">驱动AI产业人才培养供给侧变革</div> -->
      <div class="title">
        <div class="title-left">
          <img src="../assets/images/信息资讯.png" alt=""></img>
          <span class="subTitle1">产业集群</span>
          <span class="subTitle2">驱动AI产业人才培养供给侧变革</span>
        </div>
        <div class="title-right">
          <img
            class="left"
            @click="nextIndustrial('industrialSwiper')"
            @mouseenter="handleMouseEnter('industryLeft')"
            @mouseleave="handleMouseLeave('industryLeft')"
            :src="industryLeft"
            style="transform: rotate(180deg);"
            alt=""
          /> 
          <div class="line"></div>
          <img
            class="left"
            @click="nextIndustrial('industrialSwiper', 'next')"
            @mouseenter="handleMouseEnter('industryRight')"
            @mouseleave="handleMouseLeave('industryRight')"
            :src="industryRight"
            alt=""
          /> 
        </div>
      </div>
      <!-- <div class="arrow">
        <img
          class="left"
          @click="nextIndustrial('industrialSwiper')"
          src="../assets/images/32上一页、前一页.png"
          alt=""
        /> 
          class="right"
          @click="nextIndustrial('industrialSwiper', 'next')"
          src="../assets/images/32上一页、后一页.png"
          alt=""
        />
      </div> -->
      <!-- <div class="bg"></div> -->
      <div class="imgaesGroup">
        <div class="line-row" style="top:0px;"></div>
        <div class="line-row" style="top:150px;"></div>
        <div class="line-row" style="top:300px;"></div>
        <div class="line-row" style="top:450px;"></div>
        <div class="line-column" style="left:50%;"></div>
        <swiper
          ref="industrialSwiper"
          class="imagesContainer industrialSwiper"
          key="industrialSwiper"
          :options="swiperOptionChanyejiqun"
        >
          <swiper-slide
            v-for="(item, index) in currentBlock"
            class="imgItem"
            :style="{
              'justifyContent': index % 2 ==0 ? 'flex-end' : 'flex-start',
            }"
            :key="item.approve_time"
          >
            <div class="inlineCardContainer" :title="item.name" @click="toIndustryDetails(item.billid)">
              <el-image
                class="images"
                :src="item.logo"
              />
              <div class="img-word">
                <div class="img-word-name">
                  <span>{{item.name}}</span>
                </div>
                <div class="img-word-orther">
                  <span class="label">联系电话</span>
                  <span class="content">{{item.mobile}}</span>
                </div>
                <div class="img-word-orther">
                  <span class="label">单位地址</span>
                  <span class="content">{{ item.address }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      <div class="group-button">
        <button class="detail"  @click="toApplyHtml()">
          申请成为产教融合型企业
        </button>
      </div>
      </div>
    </div>
    <!-- 院校集群 -->
    <div class="group">
      <div class="title">
        <div class="title-left">
          <img src="../assets/images/信息资讯.png" alt="">
          <span class="subTitle1">院校集群</span>
          <span class="subTitle2">“双循环”产教融合人才生态模式</span>
        </div>
        <div class="title-right">
          <img
            class="left"
            @click="nextIndustrial('schoolSwiper')"
            :src="schoolLeft"
            @mouseenter="handleMouseEnter('schoolLeft')"
            @mouseleave="handleMouseLeave('schoolLeft')"
            style="transform: rotate(180deg);"
            alt=""
          /> 
          <div class="line"></div>
          <img
            class="left"
           @click="nextIndustrial('schoolSwiper', 'next')"
            :src="schoolRight"
            @mouseenter="handleMouseEnter('schoolRight')"
            @mouseleave="handleMouseLeave('schoolRight')"
            alt=""
          /> 
        </div>
      </div>
      <!-- <div class="bg"></div> -->
      <div class="imgaesGroup">
        <swiper
          class="imagesContainer"
          key="school"
          ref="schoolSwiper"
          :options="swiperOptionYuanXiaojiqun"
        >
          <swiper-slide
            v-for="(item, index) in TopBizInstitutionData"
            class="imgItem"
            :key="item.approve_time"
          >
            <el-image
              alt=""
              :fit="'fill'"
              @click="toSchoolDetails(item.billid)"
              class="images"
              :src="item.logo"
              v-if="item.logo"
            />
            <div v-if="!item.logo" class="no-imagesbox">
              <el-image class="no-images" :src="item.logo || defaultImg" alt="" />
            </div>
            <div @click.stop="toSchoolDetails(item.billid)">
              <span class="img-word">{{ item.name }}</span>
            </div>
            <p class="item-word" @click.stop="toSchoolDetails(item.billid)">
              {{ item.school_prop ? item.school_prop: '暂无' }}
            </p>
          </swiper-slide>
        </swiper>
      </div>
      <div class="group-button">
          <button class="detail" @click="toPage('school')">查看更多院校信息</button>
      </div>
    
    </div>
    <el-dialog
      title="温馨提醒"
      :visible.sync="tipDialog"
      width="30%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false">
      <div style="text-align:center; line-height:25px">本平台仅用于校企双方供需交流，供需信息由校企各方自行发布，真实性由发布人负责，但若发现供需信息不实,请您第一时间向本站检举，我们将立即核实并处理。</div>
      <br />
      <div style="text-align:center; line-height:25px; color: #2885ff;">若要申请加入联盟，请点击职教联盟处申请</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tipDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTop4Bizinfo,
  getIndustryinfoDay,
  getTopBizHomeindus,
  getNewestEnterprise,
  getTopBizInstitution,
} from "../api/home/home.js";
import { getHotBizinfos } from "../api/info/info.js";
import { mapGetters } from "vuex";
import HomeNav from "./Layout/components/HomeNav.vue";
import Footer from "../views/Layout/components/Footer.vue";
import { getBizinfoById, getBizinfos } from "../api/info/info.js";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

// 首页轮播图 目前写死
import img1 from "../assets/images/Mask group.png";
import img2 from "../assets/images/福建泉州南安新城西溪河畔全景图.png";
// import industryleft from "../assets/images/路径-左.png";
import Right from "../assets/images/路径-右.png";
import Check from "../assets/images/选中.png";
import defaultImg from "../assets/images/暂无数据默认图片.png";
import httpConfig from '@/common/utils/config.js'
export default {
  name: "home",
  computed: {
    ...mapGetters(["loginInfo"]),
  },
  data() {
    return {
      httpConfig,
      defaultImg,
      industryLeft: Right,
      industryRight: Right,
      schoolLeft: Right,
      schoolRight: Right,
      // isCheckImg: Check,
      fullscreenLoading: false,
      news: [], // 资讯信息
      newsRight: [], // 资讯信息右边
      //统计信息
      IndustryinfoData: {
        xqdjs: 0,
        qys: 0,
        hzyxs: 0,
        cxypts: 0,
      },
      // getTopBizHomeindus 查询n条产业集群库
      TopBizHomeindusData: [],
      // 查询院校信息
      TopBizInstitutionData: [],

      infoRightList: [],
      industryItemList: [],
      schoolItemList: [],
      swiperOption: {
        slidesPerView: 1,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 3000, // 5秒切换一次
        },
        // 设置轮播可循环
        loop: true,
      },
      swiperOption1: {
        slidesPerView: 1,
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // 设置点击可切换
          // clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 3000, // 5秒切换一次
        },
        // 设置轮播可循环
        loop: true,
      },
      swiperOptionChanyejiqun: {
        slidesPerView: 2,
        slidesPerColumn: 3,
        // 数据1000多条，性能优化
        
      },
      swiperOptionYuanXiaojiqun: {
        slidesPerView: 5,
        slidesPerColumn: 2,
      },
      LoginStatus: true,
      homeImgArr: [
        {
          img: img1,
        },
        {
          img: img2,
        },
      ],
      tipDialog: false,
      swiper: null,
      currentBlockIndex: 0, // 产业集群库当前页
      industryConfig: {
        blockSize: 6, // 产业集群库每页显示的条数
        list: [], // 产业集群库数据
      },
    };
  },
  computed: {
    currentBlock() {
      const startIndex = this.currentBlockIndex * this.industryConfig.blockSize;
      const endIndex = startIndex + this.industryConfig.blockSize;
      return this.TopBizHomeindusData.slice(startIndex, endIndex);
    }
  },
  created() {
    // let day = this.getNowDate(); //结果： 当前日期0点
    // console.log("当前时间:",day)
    // this.getData(day);
    this.getData();
    this.getDatatop();
    this.getInfo();
    this.getTopBizInstitutionData();
    // setInterval(() => {
      this.getTopBizHomeindusData();
    // }, 5000);
    if(this.$store.getters.firstTip){
      this.tipDialog = true;
      this.$store
      .dispatch("SetTip")
      .then((res) => {})
      .catch(() => {});
    }
  },
  mounted() {
    // this.LoginStatus=checkCookie('JSESSIONID')
  },
  methods: {
    //跳转页面
    toPage: function (page) {
      this.$router.push(`/${page}`);
    },
    nextIndustrial(domName, type = "prev") {
      if (type === "prev") {
        this.$refs[domName].$swiper.slidePrev();
      } else {
        this.$refs[domName].$swiper.slideNext();
      }

      // 产业集群库 点击左右按钮进行页数切换，解决1000多条数据性能问题
      console.log("产业集群库 点击左右按钮进行页数切换，解决1000多条数据性能问题", domName, type, this.TopBizHomeindusData.length);
      if (domName === 'industrialSwiper') {
        if (type === 'prev') {
          // 当前页数 !== 0 && 当前页数--
          this.currentBlockIndex > 0 && this.currentBlockIndex--
        } else {
          // 总数/12 !== 当前页数 && 当前页数++
          (this.TopBizHomeindusData.length >= (this.currentBlockIndex * 6)) && this.currentBlockIndex++
        }
      }
    },
    handleMouseEnter(key) {
      this[key] = Check;
    },
    handleMouseLeave(key) {
      this[key] = Right;
    },
    jumpNewDetail(id) {
      this.$router.push({
        path: "/imageText/infoDetails",
        query: {
          id: id,
        },
      });
    },
    onSwiper() {},
    onSlideChange() {},
    toInfoDetails() {
      this.$router.push("/infoDetails");
    },
    toApplyHtml() {
      // window.open(
      //   "http://oa.sinkr.cn/cjrh/indus/enterprisedeclare/start.html?processId=262ea1dd0eca4f8f92f2ae60b2074a27"
      // );
      window.open(
        "https://console.qzcjrh.cn/cjrh/indus/enterprisedeclare/start.html?processId=262ea1dd0eca4f8f92f2ae60b2074a27"
      );
    },
    toIndustryDetails(id) {
      console.log(id)
      this.$router.push({
        path: "/industry/industryDetails",
        query: { id: id },
      });
    },
    HandleToMoreInfo() {
      this.$router.push({
        path: "/imageText",
      });
    },
    toImageText() {
      if (!this.searchInp) {
        return;
      }
      this.$router.push({
        path: "/imageText",
        query: { keyword: this.searchInp },
      });
    },
    toSchoolDetails(id) {
      this.$router.push({ path: "/school/schoolDetails", query: { id: id } });
    },
    async getDatatop() {
      this.fullscreenLoading = true;
      let newsRes = await getTop4Bizinfo({size: 6});
      this.news = newsRes.data;
      this.fullscreenLoading = false;
    },
    async getTopBizHomeindusData() {
      // 机构
      let TopBizHomeindusRes = await getNewestEnterprise({ size: 2000 });
      // let TopBizHomeindusRes = await getTopBizHomeindus({ size: 1000 });
      // this.TopBizHomeindusData.push(...TopBizHomeindusRes.data);
      this.TopBizHomeindusData = TopBizHomeindusRes.data;
    },
    async getTopBizInstitutionData() {
      // 学校
      let TopBizInstitutionRes = await getTopBizInstitution({ size: 1000 });
      this.TopBizInstitutionData = TopBizInstitutionRes.data;
    },
    async getInfo() {
      //热门资讯
      let newsRightRes = await getBizinfos({
        type: "",
        keyword: "",
        orderstr: "if_top", //click_num
        pageNo: 1,
        pageSize: 8,
      });
      this.newsRight = newsRightRes.data.items;
    },
    async getData() {
      // 统计
      let IndustryinfoDayRes = await getIndustryinfoDay();
      if (IndustryinfoDayRes.data) {
        this.IndustryinfoData = IndustryinfoDayRes.data;
        console.log("数量是:",this.IndustryinfoData)
      }

      this.$store
        .dispatch("SetTopInfo")
        .then((res) => {
          // this.newsRight = res.data;
        })
        .catch(() => {});

      // // console.log()
    },
    // 点击将热词放入搜索框

    /**
     * 查询当天日期
     */
    // getNowDate() {
    //   const timeOne = new Date()
    //   const year = timeOne.getFullYear()
    //   let month = timeOne.getMonth() + 1
    //   let day = timeOne.getDate()
    //   month = month < 10 ? '0' + month : month
    //   day = day < 10 ? '0' + day : day
    //   const NOW_MONTHS_AGO = `${year}-${month}-${day}`
    //   return NOW_MONTHS_AGO
    // },
  },

  components: { HomeNav, Footer, Swiper, SwiperSlide },
};
</script>

<style scoped lang="scss">
.home {
  height: 100%;
  width: 100%;
  position: relative;
  .achAndMsg-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 350px;
    width: 100%;
    background: url("../assets/images/banner.png");
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 24px 24px 30px 24px;

    .achievement {
      width: 1100px;
      height: 118px;
      // background: #ffffff;
      // box-shadow: 0px 0px 9px 3px rgba(204, 221, 244, 0.5);
      // border-radius: 8px;
      background: rgba(20, 86, 159, 0.4);
      box-shadow: 0px 0px 9px 3px rgba(124, 121, 121, 0.5);
      border-radius: 20px 20px 20px 20px;
      opacity: 1;
      border: 2px solid #14569f;
      filter: blur(0px);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      z-index: 100;
      // margin-left: 410px;
      .achievement-word {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
      }
      .achievement-word-number {
        font-size: 40px;
        font-family: 优设标题黑;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
      .achievement-word-wz {
        margin-top: 12px;
        font-size: 24px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
      }
    }
    /* 成就词 */

    .line-box {
      display: flex;
    }
    .line {
      width: 1px;
      height: 116px;
      opacity: 0.4;
      background: #fff;
    }

    .line-coarse {
      width: 4px;
      height: 27px;
      background-color: #fff;
      margin-top: 27px;
      border-radius: 2px 2px 2px 2px;
    }
  }
}

.img1 {
  width: 180px;
  height: 180px;
  background: #d8d8d8;
}
.img2 {
  width: 100%;
  height: 100%;
}
/* 成就 */
.achievement-container {
  height: 110px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
  .achievement-list {
    width: 1200px;
    height: 110px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    .achievement-item {
      width: 300px;
      height: 110px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .achievement-word-number {
        font-weight: 600;
        display: inline-block;
        width: 200px;
        text-align: center;
        color: #2885ff;
        font-size: 30px;
        font-family: 优设标题黑;
      }
      .achievement-word-title {
        img {
          width: 12px;
          height: 12px;
          margin-right: 6px;
        }
        font-size: 12px;
        color: #8896a7;
        margin-top: 8px;
      }
    }
    .achievement-item:nth-child(1) {
      border-right: 1px solid #f1f1f1;
    }
    .achievement-item:nth-child(2) {
      border-right: 1px solid #f1f1f1;
    }
    .achievement-item:nth-child(3) {
      border-right: 1px solid #f1f1f1;
    }
  }
}
/* 信息资讯 */
.getMessage-container {
  width: 100%;
  padding: 29px 24px 40px 24px;
  box-sizing: border-box;
  background-color: #fff;
  border-top: 12px solid #fff;
  .messageCard {
    background: #ffffff;
    // box-shadow: 0px 4px 9px 1px rgba(209, 214, 222, 0.5);
    border-radius: 20px;
    filter: blur(0px);
    padding: 16px 0px 16px 0px;
    width: 1260px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    ::v-deep .swiper-pagination {
      margin-bottom: 10px;
    }
    .messageCard-title {
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .title-left {
        img {
          height: 14px;
          width: 11px;
          margin-right: 8px;
        }
        span {
          font-size: 20px;
          font-weight: Medium;
          color: #101010;
          line-height: 30px;
          font-family: Medium;
        }
      }

      .title-right {
        img {
          height: 10px;
          width: 6px;
          margin-left: 12px;
          border-radius: 6px;
        }
        span {
          font-size: 14px;
          font-weight: 500;
          color: #2885ff;
          line-height: 21px;
          font-family: Regular;
        }
      }
    }
    .messageCard-content {
      display: flex;
      flex-direction: row;
      .word-container {
        width: 650px;
        display: flex;
        flex-direction: column;
        // margin-left: 30px;
        // padding-left: 30px;
      }
      .word-container-title {
        width: 80px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(20, 86, 159, 1);
        font-size: 20px;
        font-family: Medium;
        white-space: nowrap;
        font-weight: 500;
        line-height: 20px;
        border-bottom: 3px solid #14569f;
        text-align: left;
        padding-bottom: 10px;
        // border-color: transparent transparent #14569f transparent;
      }
      .word-title-liner {
        width: 100%;
        height: 1px;
        // background-color: rgba(216, 216, 216, 1);
        /* margin-top: 16px; */
        margin-bottom: 7px;
      }
      .word-title-liner-wz {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 38px;
        padding-left: 30px;
      }
      .word-title-liner-wz-word {
        font-size: 15px;
        // font-family: Medium;
        font-weight: 500;
        color: #000000;
        line-height: 40px;
        cursor: pointer;
        // 只展示一行
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 8px;
        &:hover {
          color: #2885ff;
        }
      }
      .riqi {
        color: #999999;
        font-size: 13px;
        // font-family: Medium;
        white-space: nowrap;
        /* line-height: 40px; */
        float: right;
      }
    }
  }
}

.container99 {
  width: 650px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .text {
    width: 518px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: #2885ff;
    }
    img {
      margin-top: 10px;
    }
  }
}
.container99 > div > span > img {
  margin-right: 7px;
}

.Msg-img {
  position: relative;
  margin-right: 60px;
  margin-left: 0px;
  width: 544px;
  height: 304px;
  box-sizing: border-box;
  border-radius: 6px;
  .themImg {
    width: 544px;
    height: 304px;
  }
  ::v-deep .swiper-button-next {
    display: none;
  }
  ::v-deep .swiper-button-prev {
    display: none;
  }

  ::v-deep .swiper-pagination {
    text-align: right;
    margin-bottom: 6px;
    box-sizing: border-box;
    padding-right: 20px;
  }
  ::v-deep .swiper-pagination-bullet {
    width: 10px;
    height: 8px;
    background: #ffffff;
    border-radius: 5px;
    opacity: 0.6;
  }
  ::v-deep
    .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 4.5px;
  }
  ::v-deep
    .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet:nth-last-child(1) {
    margin: 0 20px 0 4.5px;
  }
  ::v-deep .swiper-pagination-bullet-active {
    width: 18px;
    height: 8px;
    background: #ffffff;
    border-radius: 5px;
    opacity: 1;
  }
}

::v-deep .group1 .imgaesGroup .imagesContainer .swiper-wrapper {
  flex-direction: row;
}

.Msg-img1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Msg-img-mask {
  position: absolute;
  width: 544px;
  height: 53px;
  background: rgba(16, 16, 16, 0.62);
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.Msg-img-mask-word {
  display: inline-block;
  width: 269px;
  height: 53px;
  font-size: 18px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  line-height: 53px;
  margin-left: 10px;
  // margin-top: 7px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.point-container {
  position: absolute;
  right: 0;
  top: 50%;
}
.point-container li {
  float: right;
}
.point {
  width: 10px;
  height: 8px;
  background: #ffffff;
  border-radius: 5px;
  opacity: 0.6;
  margin: 0 10px;
}
.active {
  width: 18px;
  height: 8px;
  background: #ffffff;
  border-radius: 5px;
  opacity: 1;
}
.group1 {
  // height: 632px;
  width: 100%;
  background: #ffffff;
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding-bottom: 74px;
  // align-items: center;
  .title {
    width: 100%;
    height: 54px;
    width: 1260px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 0 360px;
    .title-left {
      height: 54px;
      display: flex;
      // padding-left: 30px;
      align-items: center;
      img {
        width: 11px;
        height: 14px;
        margin-right: 8px;
      }
      .subTitle1 {
        font-size: 20px;
        font-weight: 600;
        color: #101010;
        margin-right: 28px;
        font-family: Medium;
      }
      .subTitle2 {
        font-size: 14px;
        color: #101010;
        font-weight: 500;
        font-family: Regular;
      }
    }
    .title-right {
      padding-right: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        height: 10px;
        width: 6px;
        border-radius: 6px;
      }
      .left {
        cursor: pointer;
      }
      .line {
        height: 12px;
        width: 1px;
        background-color: #bfbfbf;
        margin: 0px 20px;
      }
      .right {
        cursor: pointer;
      }
    }
  }
  .subtitle {
    margin-top: 14px;
    width: 240px;
    height: 16px;
    font-size: 16px;
    font-family: Regular;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    line-height: 16px;
    white-space: nowrap;
  }
  .arrow {
    width: 104px;
    display: flex;
    position: absolute;
    top: 133px;
    right: 348px;
    cursor: pointer;
    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      &:hover {
        transform: scale(1.2);
      }
    }
    .right {
      margin-left: 24px;
    }
  }

  .bg {
    width: 192px;
    height: 409px;
    background: #14569f;
    position: absolute;
    top: 151px;
    left: 350px;
  }
  .detail {
    width: 320px;
    height: 46px;
    background: transparent;
    font-size: 16px;
    font-family: Regular;
    font-weight: 400;
    color: #ffffff;
    // position: absolute;
    // top: 777px;
    // right: 350px;
    border-radius: 50px;
    border: 1px solid #ffffff;
    background-color: #2885ff;
    &:hover {
      cursor: pointer;
      // color: #fff;
      background: #005fdb;
    }
  }

  .imgaesGroup {
    box-sizing: border-box;
    width: 100%;
    height: 450px;
    position: relative;

    .line-row {
      height: 1px;
      width: 100%;
      position: absolute;
      background-color: #f1f1f1;
      z-index: 999;
    }
    .line-column {
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: #f1f1f1;
      z-index: 999;
    }
    .imagesContainer {
      width: 1260px;
      height: 100%;
      .imgItem {
        height: 150px;
        width: 630px;
        position: relative;
        // margin-bottom: 18px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        // border-bottom: 1px solid #F1F1F1 ;
        // padding: 4px 2px 4px 0px;
        &:hover {
          // 动态放大
          cursor: pointer;
          z-index: 999;
          transition: linear 0.3s;
          box-shadow: 0 12px 40px rgb(#000000, 12%);
          // border-color: #eee;
        }

        .inlineCardContainer {
          display: flex;
          box-sizing: border-box;
          height: 150px;
          width: 630px;
          background: #ffffff;
          // border-radius: 6px;
          filter: blur(0px);
          flex-direction: row;
          // align-items: center;
          padding: 26px 0px 0px 30px;

          // &:hover {
          //   // 动态放大
          //   cursor: pointer;
          //   z-index: 999999;
          //   box-shadow: 0 12px 40px rgb(#000000, 12%);
          //   // border-color: #eee;
          // }
          .images {
            cursor: pointer;
            width: 90px;
            height: 90px;
            border-radius: 6px;
          }
          .img-word {
            // width: 100%;
            padding-left: 20px;
            padding-top: 12px;
            height: 90px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: flex-start;
            // line-height: 24px;
            // white-space: nowrap;
            .img-word-name {
              font-family: Medium;
              font-size: 16px;
              color: #101010;
              font-weight: 600;
              margin-bottom: 12px;
            }
            .img-word-orther {
              display: flex;
              // justify-content: center;
              align-items: center;
              margin-bottom: 12px;
              .label {
                font-size: 12px;
                width: 56px;
                display: inline-block;
                color: #8896a7;
                margin-right: 20px;
                line-height: 18px;
              }
              .content {
                font-size: 12px;
                width: 400px;
                line-height: 18px;
                // height: ;
                display: inline-block;
                color: #404040;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
  .group-button {
    padding: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.group {
  height: 746px;
  width: 100%;
  background: #f6f6f6;
  display: flex;
  // padding: 0 350px;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  .title {
    margin-top: 15px;
    width: 100%;
    height: 54px;
    width: 1260px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 0 360px;
    .title-left {
      height: 54px;
      display: flex;
      // padding-left: 30px;
      align-items: center;
      img {
        width: 11px;
        height: 14px;
        margin-right: 8px;
      }
      .subTitle1 {
        font-size: 20px;
        font-weight: 600;
        color: #101010;
        margin-right: 28px;
        font-family: Medium;
      }
      .subTitle2 {
        font-size: 14px;
        color: #101010;
        font-family: Regular;
      }
    }
    .title-right {
      padding-right: 36px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      img {
        height: 10px;
        width: 6px;
        border-radius: 6px;
      }
      .left {
        cursor: pointer;
      }
      .line {
        height: 12px;
        width: 1px;
        background-color: #bfbfbf;
        margin: 0px 20px;
      }
      .right {
        cursor: pointer;
      }
    }
  }
  .subtitle {
    margin-top: 30px;
    width: 240px;
    height: 16px;
    font-size: 16px;
    font-family: Regular;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    line-height: 16px;
    white-space: nowrap;
  }
  .arrow {
    display: felx;
    position: absolute;
    top: 133px;
    left: 348px;
    cursor: pointer;
    img {
      width: 40px;
      height: 40px;
      &:hover {
        transform: scale(1.2);
      }
    }
    .right {
      margin-left: 24px;
    }
  }

  .bg {
    width: 192px;
    height: 409px;
    background: #14569f;
    position: absolute;
    top: 151px;
    right: 350px;
  }
  .detail {
    width: 118px;
    height: 46px;
    border-radius: 50px;
    background: transparent;
    font-size: 20px;
    font-family: Regular;
    font-weight: 400;
    color: #14569f;
    border: 1px solid #14569f;
    &:hover {
      cursor: pointer;
      color: #fff;
      background: #005fdb;
    }
  }
  .imgaesGroup {
    // position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 594px;
    .imagesContainer {
      width: 1260px;
      height: 100%;
      .imgItem {
        height: 272px;
        cursor: pointer;
        width: 224px !important;
        margin-bottom: 20px;
        box-sizing: border-box;
        border-radius: 6px;
        // margin-right: 12px;
        border: 1px solid #ffffff;
        background-color: #ffffff;
        // box-shadow: 0px 4px 9px 1px rgba(209, 214, 222, 0.5);
        padding: 20px 16px;
        box-sizing: border-box;
        // margin-right: 20px;

        // &:first-child {
        //   margin-left: 0px;
        // }
        &:hover {
          box-shadow: 0px 19px 40px rgba($color: #000000, $alpha: 0.12);
          // transform: translate(0,-1px);
          // transition-delay: 0s !important;
          z-index: 999;
        }
        .images {
          width: 190px;
          height: 110px;
          cursor: pointer;
          border-radius: 6px;
        }
        .no-imagesbox {
          width: 190px;
          height: 110px;
          cursor: pointer;
          border-radius: 6px;
          text-align: center;
          line-height: 110px;
          background: #F1F1F1;
          .no-images {
            width: 24px;
            height: 24px;
          }
        }
        .img-word {
          // width: 184px;
          // height: 66px;
          text-align: start;
          // background: rgba(16, 16, 16, .4);
          border-radius: 0px 0px 6px 6px;
          font-size: 16px;
          font-weight: 900;
          // font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-family: Medium;
          // font-weight: 500;
          color: #101010;
          line-height: 44px;
          // text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          width: 180px;
          padding: 0px 6px;
        }
        .item-word {
          height: 72px;
          width: 184px;
          line-height: 26px;
          padding: 0px 6px;
          font-size: 14px;
          // padding: 0px 6px;
          color: #999999;
          font-family: Regular;
          overflow: hidden;
          white-space: wrap;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .group-button {
    // height: 98px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .detail {
      width: 320px;
      height: 46px;
      background: transparent;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      // position: absolute;
      // top: 777px;
      // right: 350px;
      border-radius: 50px;
      border: 1px solid #ffffff;
      background-color: #2885ff;
      &:hover {
        cursor: pointer;
        // color: #fff;
        background: #005fdb;
      }
    }
  }
}
</style>


<style scoped lang="scss">
/* ipad pro */
@media screen and (max-width: 1024px) {
  body {
    background-color: #ff00ff;
  }
}
/* ipad */
@media screen and (max-width: 768px) {
  body {
    background-color: green;
  }
}
/* iphone6 7 8 plus */
@media screen and (max-width: 414px) {
  body {
    background-color: blue;
  }
}
/* iphoneX */
@media screen and (max-width: 375px) and (-webkit-device-pixel-ratio: 3) {
  body {
    background-color: #0ff000;
  }
}
/* iphone6 7 8 */
@media screen and (max-width: 375px) and (-webkit-device-pixel-ratio: 2) {
  body {
    background-color: #0ff000;
  }
}
/* iphone5 */
@media screen and (max-width: 320px) {
  body {
    background-color: #0ff000;
  }
}
</style>